.manekiVdo {
  width: 100%;
  height: 100vh;
  /* object-position: center bottom; */
}

.toggleBtn {
  position: absolute;
  top: 45px;
  right: 5%;
  width: 85px;
  height: 80px;
  font-size: 45px;
  line-height: 1;
  border-radius: 20px;
  padding: 20px;
  background: var(--purple) !important;
  box-shadow: 5px 5px 0 #1a082f;
  border: none !important;
  z-index: 2;
}

.playBtn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  font-size: 100px;
  line-height: 1.2;
  border-radius: 48px;
  padding: 25px 20px;
  background: var(--purple) !important;
  box-shadow: 7px 7px 0 #1a082f;
  border: none !important;
}

.playBtn p {
  font-size: 20px;
  line-height: 1;
}

.socialsDiv {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* gap: 20px; */
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.contractAddress {
  font-family: 'CatCafe';
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid var(--purple2);
  box-shadow: 3px 3px 0 var(--purple2);
  padding: 10px 15px;
  border-radius: 15px;
  font-size: 21px;
}

.socialIcons {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  /* gap: 20px; */
}

.socialIcons li a {
  display: block;
  width: 75px;
  height: 75px;
  line-height: 55px;
  border-radius: 15px;
  padding: 10px;
  background: var(--purple);
  box-shadow: 3px 3px 0 #1a082f;
  text-align: center;
}

.socialIcons li a.noLink {
  background-color: var(--purple2);
}

/* .socialsDiv.playing, */
.socialsDiv.play .contractAddress,
.socialsDiv.play .socialIcons li a {
  transform: translateX(500px);
  opacity: 0;
  pointer-events: none;
  transition: all .15s ease-in-out;
}

.socialsDiv.pause .contractAddress,
.socialsDiv.pause .socialIcons li a {
  transform: translateX(0);
  opacity: 1;
  transition: all .15s ease-in-out;
}

.manekiModal {
  width: 1150px;
  height: fit-content;
  max-width: 95%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.manekiModal .modal-dialog {
  width: 100%;
  max-width: unset;
  margin: 0 auto;
  padding: 10px;
}

.manekiModal .modal-dialog .modal-content {
  padding: 90px 55px;
  border-radius: 80px;
  border: 1px solid var(--purple2);
  box-shadow: 8px 8px 0 var(--purple2);
  max-height: 600px;
}

.manekiModal .btn-close {
  z-index: 2;
  position: absolute;
  top: 8%;
  right: 6%;
  background-color: #fff;
  background-size: 45%;
  opacity: 1;
  width: 25px;
  height: 25px;
  border-radius: 12px;
  border: 1px solid var(--purple2);
  box-shadow: 3px 3px 0 var(--purple2);
}

.aboutImg img {
  border-radius: 45px;
}

.aboutTitle {
  font-size: 120px;
  color: #E52939;
  text-shadow: 1px 0px 0 #2E282D, 0px 1px 0 #2E282D, -1px 0px 0 #2E282D, 0px -1px 0 #2E282D, 0px 10px 0 #B50000;
}

.aboutP {
  font-family: 'CatCafe';
  font-size: 21px;
  line-height: 1.2;
}

.tokenImg {
  max-width: 380px;
  margin: auto;
  transform: scale(1.1);
}

.tokenTxt h2 {
  font-size: 38px;
  background: #E52939;
  color: #fff;
  border-radius: 15px 15px 0 0;
  padding: 20px 20px 10px;
  margin: 0 10%;
}

.tokenTxt p {
  font-size: 40px;
  background: #fff;
  /* color: #2E282D; */
  border-radius: 18px;
  padding: 12px 20px 0px;
  border: 1px solid var(--purple2);
  box-shadow: 3px 3px 0 var(--purple2);
}

.tokenTxt p span {
  color: #E52939;
}

.swapCol {
  background: #E52939;
  border-radius: 20px 0 0 20px;
}

.swapCol:last-child {
  border-radius: 0 20px 20px 0;
  padding: 30px;
}

/* .swapDiv {
  border-radius: 20px;
  box-shadow: 2px 0px 5px gray;
} */

.swapDiv img {
  border-radius: 20px;
  max-height: 362px;
}

.swapTxt {
  background: #fff;
  border: 1px solid var(--purple2);
  box-shadow: 3px 3px 0 var(--purple2);
  border-radius: 10px;
  font-size: 36px;
  padding: 60px 20px 40px;
  margin-top: 60px;
}

.swapImg {
  width: 120px;
  height: 120px;
  margin: -120px auto 20px;
}

.swapImg img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}