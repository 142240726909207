.mainNavbar {
  width: 100%;
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 2;
}

.navbar-brand {
  max-width: 120px;
  position: absolute;
  margin: 0;
  padding: 0;
  left: 5%;
  top: -30px;
}

.mainNavbar .navbar-nav .nav-link {
  color: #2E282D;
  background: #fff;
  width: 300px;
  font-size: 30px;
  text-align: center;
  padding: 20px 50px 12px;
  border: 1px solid var(--purple2);
  box-shadow: 5px 5px 0 var(--purple2);
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.mainNavbar .nav-link::after {
  content: '';
  display: block;
  width: 100%;
  height: 8px;
  background: #44276588;
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.mainNavbar .nav-link.active::after {
  background: var(--purple);
  opacity: 1;
}

.mainNavbar .nav-link:hover:after {
  opacity: 1;
}

.mainNavbar.playing .navbar-brand,
.mainNavbar.playing .nav-link {
  opacity: 0.5;
}

.mainNavbar.playing .navbar-brand:hover,
.mainNavbar.playing .nav-link:hover {
  opacity: 1;
}

@media screen and (max-width:1399px) {
  .mainNavbar .navbar-nav .nav-link {
    width: auto;
  }
}

@media screen and (max-width:1199px) {
  .mainNavbar .navbar-nav .nav-link {
    font-size: 24px;
    padding: 15px 30px 10px;
  }
}

@media screen and (max-width:991px) {
  .mainNavbar {
    top: 25px;
  }

  .navbar-brand {
    max-width: 95px;
    top: -10px;
  }

  .mainNavbar .navbar-nav .nav-link {
    font-size: 22px;
    padding: 15px 25px 10px;
    box-shadow: 4px 4px 0 var(--purple2);
  }

  .mainNavbar .nav-link::after {
    height: 5px;
  }
}

@media screen and (max-width:767px) {
  .navbar-brand {
    max-width: 85px;
    top: -12px;
    left: 1%;
  }

  .mainNavbar .navbar-nav .nav-link {
    font-size: 18px;
    padding: 15px 17px 10px;
    box-shadow: 2px 3px 0 var(--purple2);
  }
}

@media screen and (max-width:575px) {
  .mainNavbar .navbar-nav .nav-link {
    font-size: 13px;
    padding: 10px 5px 5px;
  }

  .navbar-brand {
    max-width: 70px;
  }
}