@font-face {
    font-family: 'CatCafe';
    src: url('../Fonts/CatCafe.ttf');
}

@font-face {
    font-family: 'Kojiro';
    src: url('../Fonts/Kojiro.ttf');
}

:root {
    --purple: #442765;
    --purple2: #CAB9DD;
}

body {
    min-height: 100vh;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    font-weight: 400;
    font-family: 'Kojiro';
    color: #2E282D;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

a {
    text-decoration: none;
    color: unset;
}

p {
    margin-bottom: 10px;
}

img,
video {
    max-width: 100%;
    object-fit: cover;
}

ul {
    list-style-type: disc;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}