@media screen and (min-width: 1600px) {
  .container {
    max-width: 1550px;
  }
}

@media screen and (max-width:1399px) {
  .contractAddress {
    font-size: 18px;
  }

  .socialIcons li a {
    display: block;
    width: 60px;
    height: 60px;
    line-height: 40px;
  }
}

@media screen and (max-width:1199px) {
  .contractAddress {
    font-size: 16px;
  }

  .socialIcons li a {
    width: 55px;
    height: 55px;
    line-height: 35px;
  }

  .playBtn {
    width: 190px;
    font-size: 75px;
  }

  .manekiModal .modal-dialog .modal-content {
    max-height: 500px;
    padding: 75px 50px;
  }

  .aboutTitle {
    font-size: 100px;
  }

  .tokenTxt h2 {
    font-size: 34px;
  }

  .tokenTxt p {
    font-size: 36px;
  }

  .swapTxt {
    padding: 50px 20px 20px;
    margin-top: 50px;
  }

  .swapImg {
    width: 105px;
    height: 105px;
    margin: -100px auto 10px;
  }
}

@media screen and (max-width:991px) {
  .toggleBtn {
    top: 28px;
    width: 65px;
    height: 65px;
    font-size: 35px;
    border-radius: 20px;
    padding: 15px;
    box-shadow: 3px 3px 0 #1a082f;
  }

  .aboutTitle {
    font-size: 80px;
    text-shadow: 1px 0px 0 #2E282D, 0px 1px 0 #2E282D, -1px 0px 0 #2E282D, 0px -1px 0 #2E282D, 0px 7px 0 #B50000;
    line-height: 1;
  }

  .aboutP {
    font-size: 18px;
  }

  .manekiModal .modal-dialog .modal-content {
    padding: 70px 30px;
    border-radius: 50px;
    box-shadow: 5px 5px 0 var(--purple2);
  }

  .swapDiv img {
    max-height: 280px;
  }

  .swapTxt {
    font-size: 32px;
    padding: 40px 20px 10px;
  }

  .swapImg {
    width: 95px;
    height: 95px;
    margin: -95px auto 10px;
  }

  .tokenTxt h2 {
    font-size: 28px;
  }

  .tokenTxt p {
    font-size: 30px;
  }
}

@media screen and (max-width:767px) {
  .toggleBtn {
    right: 3%;
    top: 25px;
  }

  .contractAddress {
    box-shadow: 2px 2px 0 var(--purple2);
    padding: 12px 15px;
  }

  .manekiModal .modal-dialog .modal-content {
    max-height: 60vh;
    padding: 40px 30px;
    overflow: auto;
  }

  .aboutImg,
  .swapDiv,
  .tokenImg {
    max-width: 250px;
    margin: auto;
  }

  .swapCol {
    border-radius: 20px !important;
    background: transparent;
  }

  .swapCol:last-child {
    background: #E52939;
  }

  .tokenImg {
    transform: none;
  }

  .tokenTxt h2 {
    font-size: 25px;
  }

  .tokenTxt p {
    font-size: 27px;
  }
}

@media screen and (max-width:575px) {
  .playBtn {
    width: 150px;
    font-size: 55px;
    border-radius: 32px;
  }

  .toggleBtn {
    width: 50px;
    height: 50px;
    font-size: 25px;
    border-radius: 15px;
    padding: 10px;
    top: 70px;
  }

  .contractAddress {
    box-shadow: 2px 2px 0 var(--purple2);
    padding: 8px 5px;
    font-size: 11px;
  }

  .socialIcons li a {
    width: 33px;
    height: 33px;
    line-height: 0px;
    padding: 4px;
    border-radius: 5px;
  }

  .manekiModal {
    top: 47%;
  }

  .manekiModal .modal-dialog .modal-content {
    padding: 30px 10px;
    box-shadow: 3px 3px 0 var(--purple2);
    border-radius: 20px;
  }

  .aboutTitle {
    font-size: 55px;
    text-shadow: 1px 0px 0 #2E282D, 0px 1px 0 #2E282D, -1px 0px 0 #2E282D, 0px -1px 0 #2E282D, 0px 4px 0 #B50000;
  }

  .aboutP {
    font-size: 16px;
  }

  .swapTxt {
    font-size: 22px;
    padding: 40px 10px 10px;
  }

  .tokenTxt h2 {
    font-size: 22px;
  }

  .tokenTxt p {
    font-size: 24px;
    padding: 10px 20px 4px;
  }

  .manekiModal .btn-close {
    width: 20px;
    height: 20px;
    border-radius: 8px;
    border: 1px solid var(--purple2);
    box-shadow: 1px 2px 0 var(--purple2);
  }
}